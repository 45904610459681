.mining-os-comparison {
  display: flex;
  flex-direction: column;
  align-items: center;

  .page-hero {
    max-width: 1100px;
  }

  .circuit-green-rotate-second {
    display: none;

    @media (min-width: $desktop-xs) {
      display: block;
      position: absolute;
      z-index: -1;
      top: 65%;
      left: 0;
      width: 15%;
    }
  }

  .btn-sign-up {
    margin-bottom: 4rem;
  }

  &__table-title {
    font-size: $font-m-6;
    font-weight: 400;
    margin: 1.5rem auto 3rem auto;
    text-align: center;

    @media (min-width: $desktop-xs) {
      max-width: 800px;
      font-size: $font-l-2;
    }

    @media (min-width: $desktop-m) {
      max-width: 1158px;
      font-size: $font-l-5;
    }
  }

  &__table-description {
    font-size: 1rem;
    font-weight: 400;
  }

  &__main-table {
    &-wrapper {
      margin-bottom: 4rem;
      padding: 0 15px;
      background: map-get($background, 'navy-gradient');
      box-shadow: 0px 40px 40px map-get($boxShadow, 'black-transparent-70');

      @media (min-width: $mobile-xs) {
        padding: 20px;
      }

      @media (min-width: $mobile-xl) {
        margin: 0 $spacing-s 4rem $spacing-s;
        padding: 25px;
        border-radius: 6px;
      }

      @media (min-width: $tablet-xl) {
        padding: 25px 40px;
      }
    }

    max-width: 920px;

    .mining-os-comparison-table {
      &__header .mining-os-comparison-table__cell {
        hyphens: manual;

        &:nth-child(1) {
          width: 50%;
        }
        &:nth-child(2) {
          width: 30%;
          padding-right: 0.5rem;
        }
        &:nth-child(3) {
          width: 20%;
        }
      }
    }
  }

  &__pricing-table {
    &-wrapper {
      margin: $spacing-s;
      padding: 20px;
      background: map-get($background, 'navy-gradient');
      box-shadow: 0px 40px 40px map-get($boxShadow, 'black-transparent-70');
      border-radius: 6px;

      @media (min-width: $mobile-xs) {
        padding: 25px;
      }

      @media (min-width: $mobile-xl) {
        padding: 25px 40px;
      }
    }

    max-width: 700px;

    .mining-os-comparison-table {
      &__header .mining-os-comparison-table__cell {
        &:nth-child(1) {
          width: 30%;
        }
        &:nth-child(2) {
          width: 35%;
        }
        &:nth-child(3) {
          width: 35%;
        }
      }

      &__body .mining-os-comparison-table__cell {
        &:nth-child(2) {
          color: map-get($font, 'green-light');
        }
        &:nth-child(3) {
          color: map-get($font, 'orange');
        }
      }
    }
  }
}

.mining-os-comparison-table {
  width: 100%;
  font-size: 1rem;
  border-collapse: collapse;
  table-layout: fixed;

  &__header &__cell {
    font-size: $font-m-1;
    font-weight: 400;

    @media (min-width: $desktop-xs) {
      font-size: $font-m-6;
    }
  }

  &__row {
    line-height: 1.2;
    color: white;
    height: 32px;

    &:not(:only-child) {
      border-top: 2px solid map-get($border, 'grey-darker');
    }
  }

  &__cell {
    height: 60px;
    padding: 15px 0;
    text-align: left;

    &:not(:first-child) {
      text-align: center;
    }

    &--with-notes {
      vertical-align: top;
    }
  }

  &__cell:nth-child(2) {
    padding-right: 0.5rem;
  }

  &__cell-notes {
    margin: 0.25rem auto 0 auto;
    font-size: 0.75rem;
    text-align: center;
    color: map-get($font, 'grey');
    white-space: pre-wrap;
  }

  .tick {
    height: 28px;
    width: 28px;
    margin: 0 auto;
    background-image: url(/compare-ticks-sprite.svg);
    font-size: 0;
    color: transparent;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 50%;

    &--limited {
      background-position: 100% 50%;
    }

    &--disabled {
      background-position: 50% 50%;
    }
  }
}
