.mining-calculator {
  max-width: 1400px;
  margin: auto;

  .section-title {
    margin-top: 70px;
  }

  &__header-disclaimer {
    text-align: center;
  }

  &__header-disclaimer-link {
    color: map-get($font, 'blue');
    margin-left: 2px;
  }

  &__show-button {
    width: fit-content;
    margin: 40px auto;
  }

  &__hashrate-number {
    min-width: 50px;
  }

  &__hashrate-cell-wrapper {
    .static-table__header-cell {
      display: flex;
      justify-content: flex-end;
      padding-right: 60px;
    }
  }

  &__hashrate-cell {
    min-width: 160px;
    text-align: right;
    padding-right: 60px;
  }

  &__power-cell-wrapper {
    .static-table__header-cell {
      display: flex;
      justify-content: flex-end;
      padding-right: 70px;
    }
  }

  &__power-cell {
    min-width: 120px;
    text-align: right;
    padding-right: 70px;
  }
}
