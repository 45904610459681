.flash-drive__content-text {
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: $desktop-s) {
    gap: 33px;
    align-items: normal;
    // margin: 0 150px;
  }

  &-line {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 30px;
    height: 100%;
    width: 2px;

    &-arrow {
      width: 2px;
      height: 100%;
      background: map-get($background, 'white-to-transparent-gradient-top');

      @media (min-width: $desktop-s) {
        width: 100%;
        height: 2px;
        background: map-get($background, 'white-to-transparent-gradient-left');
      }
    }

    &-ball {
      width: 16px;
      height: 16px;
      border: 1.5px solid #ffffff;
      border-radius: 100%;
    }

    @media (min-width: $desktop-s) {
      width: 100%;
      margin: 0;
    }

    // &--mobile {
    //   display: none;
    //   //   @media (min-width: $desktop-s) {
    //   //     display: none;
    //   //   }
    // }

    &--desktop {
      display: none;

      @media (min-width: $desktop-s) {
        display: flex;
      }
    }
  }

  &-top,
  &-bottom {
    position: relative;
    display: flex;
    align-items: center;

    @media (min-width: $desktop-s) {
      margin: 0 $multi-section-gap 0 $multi-section-gap;

      //   width: 100%;
    }
  }

  &-top {
    flex-direction: row-reverse;

    span {
      color: map-get($font, 'blue');
    }

    .flash-drive__content-text-header,
    .flash-drive__content-text-description {
      @media (min-width: $desktop-s) {
        text-align: right;
      }
    }

    @media (min-width: $desktop-s) {
      margin-top: 0px;

      .flash-drive__content-text-line {
        flex-direction: row;
      }
    }
  }

  &-bottom {
    span {
      color: map-get($font, 'blue');
    }

    .flash-drive__content-text-header,
    .flash-drive__content-text-description {
      @media (min-width: $desktop-s) {
        text-align: left;
      }
    }
    @media (min-width: $desktop-s) {
      margin-top: 0px;

      .flash-drive__content-text-line {
        flex-direction: row-reverse;
        &-arrow {
          transform: rotate(180deg);
        }
      }
    }
  }

  &-header,
  &-description {
    // margin-top: 15px;
    font-weight: 400;
  }

  &-header {
    font-size: $font-l-3;

    @media (min-width: $desktop-xs) {
      font-size: $font-l-5;
    }
  }

  &-description {
    font-size: $font-m-1;
    text-align: center;

    @media (min-width: $mobile-xl) {
      max-width: 450px;
      margin: 15px auto 0 auto;
    }

    @media (min-width: $desktop-xs) {
      font-size: $font-m-1;
    }
  }

  //   &__link-wrapper {
  //     @media (min-width: $desktop-s) {
  //       min-width: 100%;
  //     }
  //   }

  //   &__link {
  //     position: relative;
  //     z-index: 2;
  //     margin-top: 60px;

  //     @media (min-width: $desktop-s) {
  //       margin-top: 80px;
  //     }
  //   }
}
