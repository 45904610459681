.features-slider {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 70px $hero-gap 120px $hero-gap;

  @media (min-width: $desktop-xs) {
    flex-direction: row;
  }

  .swiper {
    position: relative;
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .section-title {
      padding: 0;
      align-items: center;
      margin-top: 0;
      margin: 0;

      @media (min-width: $desktop-xs) {
        margin: 0 0 0 70px;
        align-items: start;
      }

      &__description {
        margin: 0;
        text-align: center;

        @media (min-width: $desktop-xs) {
          text-align: start;
        }
      }
    }
  }

  &__card {
    margin-bottom: 0;
    cursor: grab;
  }
}
