.footnotes {
  height: 202px;

  @media (min-width: $desktop-xs) {
    display: flex;
    align-items: flex-end;
    width: 88%;
    justify-content: center;
    margin-top: 55px;
    background-color: rgba(34, 35, 50, 0.5);
    border-radius: 64px 64px 0 0;

    .footnotes__links-list {
      order: 2;
    }

    .footnotes__socials {
      order: 1;
    }

    .footnotes__copyright {
      order: 3;
    }
  }

  &__links-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 40px 40px 40px;
    gap: 5px;

    @media (min-width: $desktop-xs) {
      display: flex;
      flex-direction: row;
      padding: 0 40px 20px 40px;
      gap: 20px;
      margin: 5px;
    }

    @media (min-width: $desktop-s) {
      display: flex;
      flex-direction: row;
      padding: 0 40px;
      gap: 20px;
      margin: 30px;
    }
  }

  &__links-list {
    margin: 30px auto 20px;

    @media (min-width: $desktop-xs) {
      display: flex;
      flex-direction: row;
      margin: 0;
    }

    li {
      margin: 15px 0;
      text-align: center;
      white-space: nowrap;

      @media (min-width: $desktop-xs) {
        margin: 0;
        margin-right: 15px;
      }

      @media (min-width: $desktop-s) {
        margin-right: 30px;
      }

      @media (min-width: $desktop-m) {
        margin-right: 36px;
      }
    }
  }

  &__socials {
    display: flex;
    gap: 5px;

    @media (min-width: $desktop-xs) {
      margin: 5px;
      gap: 8px;
    }

    @media (min-width: $desktop-s) {
      margin: 30px;
      gap: 16px;
    }

    .facebook-logo,
    .twitter-logo {
      height: 43px;
      cursor: pointer;

      rect,
      path {
        transition: 0.2s;
      }

      &:hover rect {
        fill: map-get($background, 'white');
      }
    }

    .facebook-logo {
      &:hover path {
        fill: map-get($background, 'blue-light');
      }
    }

    .twitter-logo {
      &:hover path {
        fill: map-get($background, 'blue');
      }
    }
  }

  &__copyright {
    text-align: center;
    color: map-get($font, 'grey');

    @media (min-width: $desktop-xs) {
      font-size: $font-s-1;
    }

    @media (min-width: $desktop-s) {
      font-size: $font-s-2;
    }

    @media (min-width: $desktop-m) {
      font-size: $font-s-3;
    }
  }
}
