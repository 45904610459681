.tabs {
  &__navigation-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 70px;
  }

  &__navigation-gap {
    &--left,
    &--right,
    &--center {
      width: 100%;
      height: 70px;
      border-bottom: solid 1px map-get($border, 'grey-darkest');
    }

    &--center {
      width: 20px;

      @media (max-width: $mobile-l) {
        width: 15px;
      }

      @media (max-width: $mobile-download-tabs-s) {
        width: 10px;
      }
    }
  }

  &__navigation-list {
    display: flex;

    li {
      display: flex;
    }

    &:first-child {
      margin-right: 20px;
    }
  }

  &__navigation-item {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 70px;
    border-radius: 6px 6px 0 0;
    background: map-get($background, 'grey-darkest');
    border: solid 1px map-get($border, 'grey-darkest');
    cursor: pointer;
    transition: background-color 0.2s, border-color 0.2s;

    @media (max-width: $mobile-l) {
      width: 180px;
    }

    @media (max-width: $mobile-download-tabs-s) {
      width: 165px;
    }

    @media (max-width: $mobile-xs) {
      flex: wrap;
      width: 140px;
    }

    span {
      display: flex;
      align-items: center;
    }

    .navigation-beta-badge {
      margin-left: 15px;
      padding: 4px 6px;
      font-size: $font-s-2;
      border-radius: 4px;
      background: map-get($background, 'blue-light');
      transition: background-color 0.2s;

      @media (max-width: $mobile-download-tabs-s) {
        margin-left: 10px;
      }

      @media (max-width: $mobile-xs) {
        position: absolute;
        top: 0;
        right: 0;
        padding: 3px 8px;
      }
    }

    &:hover {
      background: map-get($background, 'blue-light');

      .navigation-beta-badge {
        background: map-get($background, 'grey-darkest');
      }
    }

    &-icon {
      margin-right: 13px;

      @media (max-width: $mobile-download-tabs-s) {
        margin-right: 10px;
      }
    }

    &.active {
      background: transparent;
      border: solid 1px map-get($border, 'grey-darkest');
      border-bottom: solid 1px map-get($background, 'transparent');
      pointer-events: none;
    }
  }
}
