.static-table {
  width: 100%;
  border-collapse: collapse;

  &__wrapper {
    position: relative;
    background: map-get($background, 'navy');
    padding: 20px $spacing-xs;
    overflow-x: auto;
    border-radius: 8px;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (min-width: $mobile-xl) {
      margin: 0 $spacing-m;
      padding: 20px $spacing-s;
      border-radius: 16px;
    }
  }

  &__header-row {
    text-align: left;
    vertical-align: top;
  }

  &__header-cell-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 0 20px 0;
    font-weight: 400;
  }

  &__header-title {
    font-size: $font-s-4;
  }

  &__header-description {
    margin-top: 4px;
    font-size: $font-s-3;
    color: map-get($font, 'grey');
  }

  &__row {
    border-bottom: 2px solid map-get($border, 'grey-dark');
  }

  &__cell {
    height: 60px;
    font-size: $font-s-4;
  }
}
