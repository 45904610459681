.frequently-questions {
  .question-marks-icon {
    display: block;
    width: 100px;
    margin: 80px auto 0 auto;

    @media (min-width: $desktop-xs) {
      width: 140px;
    }
  }

  &__header {
    margin: 25px auto 50px auto;
    padding: 0 $spacing-s;
    text-align: center;
  }
}
