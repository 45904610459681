.download {
  &__content-wrapper {
    margin: 0 3%;

    @media (min-width: $desktop-xs) {
      display: flex;
      max-width: 1340px;
      margin: auto;
      padding: 0 $spacing-m;
    }
  }

  &__content-requirements {
    display: flex;
    flex-direction: column;

    max-width: 400px;
    margin: 0 auto;

    @media (min-width: $desktop-xs) {
      gap: 20px;
      min-width: 280px;
      margin-top: 0;
      padding: 0;

      justify-content: space-between;
    }

    @media (min-width: $desktop-s) {
      min-width: 340px;
    }

    @media (min-width: $desktop-m) {
      min-width: 400px;
    }
  }
}
