.navigation-bar-menu {
  position: absolute;
  z-index: 11;
  top: 0;
  left: -100%;
  width: calc(100% - 50px);
  max-width: 368px;
  height: 100vh;
  background: map-get($background, 'navy-darker');
  opacity: 0;
  overflow-y: auto;
  transition:
    left 0.25s ease-in,
    opacity 0.25s ease-in;

  @media (min-width: $tablet-xl) {
    transition: none;
    background: map-get($background, 'navy-dark');
    overflow-y: unset;
  }

  @media (min-width: $tablet-xl) {
    display: flex;
    position: static;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    height: 100%;
    background: map-get($background, 'transparent');
    opacity: 1;
  }

  &--open {
    left: 0;
    opacity: 1;
  }

  &__title {
    display: flex;
    height: 68px;
    padding: 22px 20px 0 64px;
    align-items: center;
    font-size: $font-s-3;
    text-transform: uppercase;
    color: map-get($font, 'white');

    font-family: 'Outfit', sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 18.9px;

    @media (min-width: $mobile-xs) {
      height: 76px;
    }

    @media (min-width: $tablet-xl) {
      display: none;
    }
  }

  &__list-wrapper {
    display: block;
    width: 100%;

    @media (min-width: $tablet-xl) {
      height: 100%;
    }

    &::-webkit-scrollbar {
      width: 0.5em;
    }

    &::-webkit-scrollbar-track {
      background-color: map-get($background, 'transparent');
    }

    &::-webkit-scrollbar-thumb {
      background-color: map-get($background, 'white-transparent-87');
      border-radius: 3px;
    }

    @media (min-width: $tablet-xl) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__list {
    width: 100%;
    margin-top: 30px;

    @media (min-width: $mobile-s) {
      margin-top: 40px;
    }

    @media (min-width: $tablet-xl) {
      display: flex;
      align-items: center;
      margin: 0 auto 0 auto;

      li:last-child {
        margin: 0 10px 0 auto;
      }
    }
  }

  &__list-item {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 1.375rem;
    color: map-get($font, 'white');

    &-link {
      display: flex;
      align-items: center;
      height: 100%;
      min-height: 40px;
      width: 100%;
      padding: 5px 15px;
      font-weight: 600;
      color: map-get($font, 'white');
      cursor: pointer;
      transition: background 0.3s;

      @media (min-width: $tablet-xl) {
        padding: 0 30px;
      }

      &--active {
        color: map-get($font, 'blue');
      }

      &:hover {
        background: map-get($background, 'navy');

        @media (min-width: $tablet-xl) {
          background: map-get($background, 'navy-dark');
        }
      }
    }

    @media (min-width: $mobile-xs) {
      height: 40px;
    }

    @media (min-width: $mobile-s) {
      height: 45px;
      font-size: 1.625rem;
    }

    @media (min-width: $tablet-xl) {
      min-height: 100%;
      font-size: $font-s-3;
    }

    @media (min-width: $desktop-s) {
    }

    @media (min-width: $desktop-m) {
      font-size: $font-s-4;
    }
  }

  .active {
    background: map-get($background, 'navy-dark');

    @media (min-width: $desktop-s) {
      background: none;
    }

    &:hover {
      cursor: default;
    }
  }
}

.navigation-bar--on-start {
  .active {
    @media (min-width: $desktop-s) {
      background: map-get($background, 'navy-dark');
    }
  }
}
