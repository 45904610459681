.download-card {
  margin: 0 auto 20px auto;
  background: map-get($background, 'navy-gradient-transparent-50');
  border-radius: 32px;
  border: 1px solid map-get($border, 'navy');
  padding: 20px;

  @media (min-width: $mobile-xl) {
    // margin: 0 $spacing-m 25px $spacing-m;
    // display: flex;
    border-radius: 32px;
    padding: 30px;
  }

  @media (min-width: $desktop-xs) {
    margin: 0 0 0 0;
    padding: 44px;
  }

  &__description-wrapper {
    @media (min-width: $mobile-xl) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__title {
    color: map-get($font, 'blue-light');
    font-size: $font-s-3;

    @media (min-width: $mobile-s) {
      font-size: $font-s-4;
    }
  }

  &__header {
    margin-top: 10px;
    font-size: $font-m-2;

    @media (min-width: $mobile-s) {
      font-size: $font-m-3;
    }

    @media (min-width: $desktop-xs) {
      font-size: $font-m-4;
    }

    @media (min-width: $desktop-s) {
      font-size: $font-m-5;
    }
  }

  &__description {
    margin-top: 8px;
    font-size: $font-s-2;

    @media (min-width: $mobile-s) {
      font-size: $font-s-3;
    }
  }

  &__type {
    height: 36px;
    min-height: fit-content;
    width: 83px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid map-get($border, 'blue');
    border-radius: 42px;
    color: map-get($font, 'blue');
    font-size: $font-s-4;
    margin-top: 8px;
    @media (min-width: $mobile-xl) {
      height: 46px;
      min-height: fit-content;
      width: 93px;
    }
  }

  &__download-btn-wrapper {
    margin-top: 25px;
    background: map-get($background, 'grey-darker');
    border-radius: 4px;
    // zmiana na czas ukrycia mirros
    height: fit-content;

    @media (min-width: $desktop-xs) {
      width: 170px;
      margin-top: 0;
    }
  }

  &__download-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;

    &-title {
      margin-right: 10px;
    }
  }

  &__mirror-wrapper {
    border-radius: 0 0 4px 4px;
  }

  &__mirror-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 12px 0;

    &:hover {
      .download-card__mirror-link-title {
        color: map-get($font, 'blue-light');
      }
    }
  }

  &__mirror-link-title {
    transition: 0.25s;
    color: map-get($font, 'white');
  }

  &__mirror-link-size {
    color: map-get($font, 'navy-lighter');
    margin-left: 3px;
  }

  &__details {
    white-space: break-spaces;
    padding: 20px 0;
    color: map-get($font, 'grey');
  }
}
