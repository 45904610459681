.changelog-navigation {
  &__list {
    display: flex;
    flex-direction: column;
    padding: 0 $spacing-xs;

    @media (min-width: $mobile-xl) {
      flex-direction: row;
      padding: 0;
    }
  }

  &__list-item {
    margin-bottom: 5px;
    font-size: $font-s-3;
    color: map-get($font, 'grey');

    &:hover,
    &.active {
      color: map-get($font, 'blue');
    }

    @media (min-width: $mobile-xl) {
      margin-right: 15px;
      margin-bottom: 0;
    }

    @media (min-width: $tablet-xs) {
      margin-right: 25px;
      font-size: $font-s-4;
    }

    @media (min-width: $tablet-xl) {
      margin-right: 35px;
      font-size: $font-m-1;
    }

    @media (min-width: $desktop-m) {
      margin-right: 40px;
      font-size: $font-m-2;
    }
  }
}
