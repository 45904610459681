.slider-text {
  display: flex;
  text-align: start;
  flex-direction: column;
  justify-content: center;

  height: 340px;

  @media (min-width: $desktop-xs) {
    height: 100%;
    padding: 10% 10%;
  }
}

.slider-text-buttons {
  position: relative;
  display: flex;
  gap: 20px;
  margin-top: $spacing-l;

  .swiper {
    &-button-prev {
      left: auto;
      right: calc($spacing-s + 55px);

      @media (min-width: $mobile-xs) {
        right: calc($spacing-m + 55px);
      }

      @media (min-width: $desktop-xs) {
        left: calc(15% - $spacing-m);
        right: auto;
      }
    }

    &-button-next {
      right: $spacing-s;

      @media (min-width: $mobile-xs) {
        right: $spacing-m;
      }

      @media (min-width: $desktop-xs) {
        left: calc(15% + 35px);
        right: auto;
      }
    }

    &-button-prev,
    &-button-next {
      position: static;
      width: 40px;
      height: 40px;
      margin: 0;
      border-radius: 100%;
      border: 2px solid;
      color: map-get($font, 'grey');
      background: map-get($background, 'navy-dark');

      &:hover {
        border: 2px solid map-get($border, 'blue');
        color: map-get($font, 'blue');
      }

      &::after {
        font-size: $font-s-3;
        font-weight: 700;
      }

      @media (min-width: $desktop-xs) {
        bottom: calc(15% + 50px);
        width: 45px;
        height: 45px;
      }

      @media (min-width: $desktop-xs) {
        &::after {
          font-size: $font-s-4;
        }
      }
    }

    &-button-disabled {
      display: none;
    }
  }
}
