.cookies-consent {
  position: relative;
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 50px;
  padding: 15px $spacing-s;
  background: map-get($background, 'navy-dark');
  text-align: center;

  @media (min-width: $tablet-m) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 $spacing-s;
    margin: 0;
  }

  @media (min-width: $desktop-xs) {
    justify-content: center;
  }

  &__link {
    margin-left: 10px;
    color: map-get($font, 'blue');
    transition: 0.25s;

    &:hover {
      color: map-get($font, 'blue-light');
    }
  }

  &__text {
    @media (min-width: $desktop-xs) {
      flex: 1;
    }
  }

  &__btn {
    width: 120px;
    height: 34px;
    margin-top: 20px;
    font-size: $font-s-3;

    @media (min-width: $tablet-m) {
      margin: 0 0 0 $spacing-s;
    }
  }
}
