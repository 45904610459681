.gpus-brands-wrapper {
  max-width: $hugeScreens;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gpus-brands {
  height: 100%;
  margin: 0 $multi-section-gap;

  @media (min-width: $desktop-s) {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-width: 1440px;
  }

  &__background {
    position: static;
    display: flex;
    outline: 2px solid map-get($background, 'navy-darker');
    box-shadow: -5px -2px 50px 50px map-get($background, 'navy-darker') inset;

    @media (min-width: $mobile-xl) {
      box-shadow: -20px 15px 100px 120px map-get($background, 'navy-darker')
        inset;
    }

    @media (min-width: $tablet-xl) {
      box-shadow: -20px 15px 150px 200px map-get($background, 'navy-darker')
        inset;
    }

    @media (min-width: $desktop-s) {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      margin-right: 100px;
      box-shadow: -100px 0 150px 200px map-get($background, 'navy-darker') inset;
    }

    img {
      z-index: -1;
      width: 100%;
      height: 100%;

      @media (min-width: $desktop-s) {
        width: 80%;
        height: auto;
      }
    }
  }

  &__content-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 $multi-section-gap;
    gap: 30px;

    .section-title {
      text-align: center;
      margin-bottom: 20px;

      @media (min-width: $desktop-s) {
        text-align: left;
        margin: 0;
      }
    }
    .section-title__header {
      margin: 0;
    }

    @media (min-width: $desktop-s) {
      position: absolute;
      margin-right: $spacing-l;
      z-index: 1;
      max-width: 522px;
      max-height: 604px;
      background-image: linear-gradient(
        to left,
        rgba(12, 16, 28, 1),
        rgba(12, 16, 28, 1),
        rgba(12, 16, 28, 0.8)
      );
      box-shadow: 20px 0 250px 105px rgba(12, 16, 28, 0.8);
    }

    @media (min-width: $desktop-xs) {
      // width: 60%;
      // padding: 35px 0 60px $spacing-m;
    }
  }

  &__content-header {
    font-size: $font-m-6;
    text-align: left;

    @media (min-width: $desktop-xs) {
      font-size: $font-l-2;
    }

    @media (min-width: $desktop-m) {
      font-size: $font-l-5;
    }
  }

  &__content-description {
    // margin: 25px 0 45px 0;

    font-size: $font-m-1;
    text-align: left;
    color: map-get($font, 'grey');

    @media (min-width: $desktop-xs) {
      font-size: $font-m-1;
    }

    @media (min-width: $desktop-m) {
      font-size: $font-s-4;
    }
  }

  &__content-card {
    // padding: 40px 25px 50px 25px;
    text-align: center;

    .card__description {
      color: map-get($font, 'grey');
    }

    @media (min-width: 1200px) {
      // padding: 40px 35px 50px 35px;
    }

    .card__header {
      // margin: 10px 0 20px 0;
      font-size: $font-m-4;
    }
  }

  &__content-ending-note {
    text-align: right;
    color: map-get($font, 'blue-light');
    // font-size: $font-m-4;

    // @media (min-width: $mobile-xs) {
    //   font-size: $font-m-5;
    // }
  }
}
