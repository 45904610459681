.pricing {
  margin: 100px 3% 40px 3%;
  @media (min-width: $desktop-s) {
    margin: 160px $section-gap 50px $section-gap;
  }
}

.pricing-support {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pricing-examples {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 32px;

  hr {
    height: 1px;
    background: map-get($border, 'navy');
  }

  &:nth-child(2) {
    gap: 32px;
    opacity: 100%;
  }

  &__element {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    padding: 20px 30px 10px 30px;
    background-image: map-get($background, 'navy-gradient-transparent-50');
    border: 1px solid map-get($border, 'navy');
    border-radius: 32px;
    gap: 16px;

    @media (min-width: $mobile-l) {
      padding: 32px $spacing-l 22px $spacing-l;
      gap: 32px;
    }

    &--opacity {
      width: 80%;
      height: 70%;
      gap: 16px;
      opacity: 60%;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: $font-m-2;
      font-weight: 700;

      @media (min-width: $mobile-xl) {
        font-size: $font-m-5;
        gap: 32px;
      }

      .nvidia-logo {
        width: 25%;
        height: 10%;

        @media (min-width: $mobile-xl) {
          width: 106;
          height: 20px;
        }
      }
    }

    &-message {
      text-align: center;
      margin-top: 10px;

      &-link {
        color: map-get($font, 'blue');
        margin-left: 3px;
      }
    }

    &-type-and-size {
      display: flex;
      gap: 10px;
    }

    &-prices {
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-size: $font-s-4;

      &-single {
        display: flex;
        justify-content: space-between;
      }

      @media (min-width: $mobile-xl) {
        font-size: $font-m-1;
      }
    }
  }
}
