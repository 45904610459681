.chip-quantity-cell {
  display: flex;
  align-items: center;
  height: 30px;
  min-width: 190px;

  &__counter-subtract-btn,
  &__counter-add-btn,
  &__counter-input {
    height: 30px;
    background: map-get($background, 'white');
  }

  &__counter-subtract-btn,
  &__counter-add-btn {
    position: relative;
    width: 30px;
    cursor: pointer;

    &:disabled {
      pointer-events: none;
    }

    &:disabled::after,
    &:disabled::before {
      border-color: map-get($border, 'grey-light');
    }
  }

  &__counter-subtract-btn {
    border-radius: 4px 0 0 4px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      border-bottom: 2px solid map-get($border, 'black');
      transition: 0.25s;
    }

    &:hover::after {
      border-color: map-get($border, 'blue-light');
    }
  }

  &__counter-add-btn {
    border-radius: 0 4px 4px 0;

    &::after,
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      border-bottom: 2px solid map-get($border, 'black');
    }

    &::before {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &:hover::after,
    &:hover::before {
      border-color: map-get($border, 'blue-light');
    }
  }

  &__counter-input {
    width: 82px;
    font-size: $font-s-4;
    text-align: center;
    border-left: solid 1px map-get($border, 'grey-light');
    border-right: solid 1px map-get($border, 'grey-light');

    &:focus {
      outline: none;
      border-color: map-get($border, 'blue-light');
    }
  }

  &--muted {
    .chip-quantity-cell__counter-subtract-btn,
    .chip-quantity-cell__counter-add-btn,
    .chip-quantity-cell__counter-input {
      background: map-get($background, 'navy-light');
      border-color: map-get($border, 'grey-dark');
      color: map-get($font, 'white');
    }
  }
}
