.language-dropdown {
  position: absolute;
  top: 30px;
  right: 24px;
  width: 48px;
  height: 35px;

  @media (min-width: $mobile-s) {
    top: 34px;
  }

  @media (min-width: $tablet-xl) {
    position: relative;
    top: 0;
    right: 0;
  }

  &__toggle {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-right: 3px;
    background: map-get($background, 'transparent');
    cursor: pointer;

    .flag-usa,
    .flag-russia,
    .flag-china,
    .flag-spain,
    .flag-thai {
      opacity: 1;
      transition: 0.2s;
    }

    &:hover {
      .flag-usa,
      .flag-russia,
      .flag-china,
      .flag-spain,
      .flag-thai {
        opacity: 0.8;
      }

      .language-dropdown__toggle-arrow {
        span {
          border-color: map-get($border, 'blue');
        }
      }
    }

    .flag-usa {
      height: 26px;
    }

    .flag-russia,
    .flag-china,
    .flag-spain,
    .flag-thai {
      height: 18px;
    }
  }

  &__toggle-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    margin: 0 0 0 auto;
    width: fit-content;

    span {
      margin-bottom: 4px;
      padding: 2px;
      border: solid map-get($border, 'white');
      border-width: 0 2px 2px 0;
      cursor: pointer;
      transition: 0.2s linear;
      transform: rotate(45deg);
    }

    &--open {
      span {
        transform: rotate(135deg);
      }
    }
  }

  &__menu {
    display: none;
    position: relative;
    z-index: 14;
    top: 20px;
    right: 100%;
    width: 140px;
    transform: translateX(-50%);

    @media (min-width: $tablet-xl) {
      position: absolute;
      z-index: 14;
      right: 0;
      top: 60px;
      height: 50px;
      width: 300px;
      transform: none;
    }

    &--open {
      display: block;
    }
  }

  &__menu-item-link {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 6px 10px;
    color: map-get($font, 'white');
    border-radius: 2px;
    font-size: $font-s-4;
    background: map-get($background, 'navy');
    transition: 0.2s;

    .flag-usa,
    .flag-russia,
    .flag-china,
    .flag-spain,
    .flag-thai {
      width: 40px;
      margin-right: 10px;
    }

    &:first-child {
      margin-bottom: 15px;
    }

    &:hover {
      background: map-get($background, 'blue');
    }
  }

  &__curtain {
    position: fixed;
    z-index: 13;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: map-get($background, 'black-transparent-20');
  }

  .flag-usa {
    fill: map-get($font, 'white');
  }
}
