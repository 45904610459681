.link-type-underline {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  color: map-get($font, 'white');
  transition: 0.2s;

  &::after {
    position: absolute;
    left: 0;
    bottom: -1px;
    content: '';
    display: block;
    min-height: 1px;
    width: 0;
    background: map-get($background, 'blue-light');
    transition: 0.2s ease-in;
  }

  &:hover,
  &.active {
    color: map-get($font, 'blue-light');
  }

  &.active {
    border-color: map-get($border, 'blue-light');
  }

  &:hover::after {
    width: 100%;
  }
}

.link-type-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  color: map-get($font, 'blue');
  transition: 0.25s;

  @media (min-width: $desktop-xs) {
    justify-content: flex-start;
  }

  &-title {
    margin-right: 10px;
    font-size: $font-m-1;
  }

  .arrow-circle-icon g,
  .arrow-circle-icon path {
    transition: 0.25s;
  }

  &:hover {
    color: map-get($font, 'blue-light');

    .arrow-circle-icon g,
    .arrow-circle-icon path {
      stroke: map-get($font, 'blue-light');
    }
  }
}

.editor__link {
  color: map-get($font, 'blue');
}
