.flash-tutorial-wrapper {
  margin: 0 auto;
  padding: 0 $spacing-s;

  @media (min-width: $desktop-xs) {
    padding: 0 $spacing-m;
    position: relative;
    display: flex;
    justify-content: center;
  }
}

.flash-tutorial-content {
  margin: auto;

  @media (min-width: $desktop-m) {
    max-width: 1240px;
  }

  .page-hero-article {
    padding: 0;

    &__header {
      text-align: left;
    }

    &__description {
      text-align: left;
      color: map-get($font, 'grey');
    }
  }

  &__pictures-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: -30px;
    margin-top: -30px;

    @media (min-width: $tablet-s) {
      flex-direction: row;
      justify-content: space-between;
    }

    & > div {
      margin-top: 30px;
      margin-left: 30px;
      flex: 1;
    }
  }

  code {
    display: block;
    line-height: normal;
    font-size: 0.75em;
    margin-top: 1rem;
  }
}

.flash-tutorial-aside {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: center;

  .support-discord {
    width: 100%;
    max-width: 400px;
    margin: 40px;
    z-index: 1;
  }

  @media (min-width: $desktop-xs) {
    flex-direction: column;

    .support-discord {
      width: 300px;
    }
  }

  @media (min-width: $desktop-m) {
    .support-discord {
      width: 350px;
    }
  }
}

.flash-tutorial-article {
  @mixin text-decoration {
    text-decoration: underline;
    text-decoration-thickness: 0.075em;
    text-underline-offset: 0.075em;
  }

  position: relative;

  &__heading {
    margin-top: 2.75rem;
    color: map-get($font, 'white');
    font-weight: 400;

    &--two {
      font-size: $font-m-3;
    }

    &--three {
      font-size: $font-m-2;
    }
  }

  &__description {
    margin: 30px 0;
    font-size: $font-s-4;
    line-height: $font-m-4;
    color: map-get($font, 'grey');
  }

  &__text-paragraph {
    margin-top: 10px;
  }

  &__link {
    @include text-decoration;
    color: inherit;
    transition: color 300ms;

    &:hover {
      @include text-decoration;
      color: map-get($font, 'blue-light');
    }
  }

  &--divider {
    &-top {
      border-top: solid 2px map-get($border, 'grey-dark');
    }

    &-bottom {
      border-bottom: solid 2px map-get($border, 'grey-dark');
    }
  }

  @media (min-width: $desktop-xs) {
    &__heading {
      line-height: $font-m-8;

      &--two {
        font-size: $font-m-5;
      }

      &--three {
        font-size: $font-m-4;
      }
    }

    &__description {
      font-size: $font-m-1;
      line-height: $font-m-5;
    }
  }

  @media (min-width: $desktop-m) {
    &__description {
      font-size: $font-m-3;
      line-height: $font-m-7;
    }
  }
}

.flash-tutorial-unordered-list {
  li {
    padding-left: 1.375em;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0.75em;
      transform: translateY(-50%);
      width: 0.35em;
      height: 0.35em;
      background-color: map-get($background, 'blue-light');
    }

    @media (min-width: $desktop-xs) {
      font-size: $font-m-3;
      margin-bottom: 0.5rem;
    }

    a {
      margin-left: 5px;
    }
  }

  &--nested {
    padding-left: 1.375rem;

    li::before {
      background-color: map-get($background, 'white');
    }
  }
}

.related-articles {
  max-width: 400px;
  padding: 40px 28px 0 28px;
  border-radius: 6px;
  background: map-get($background, 'navy-gradient-transparent-50');
  z-index: 1;

  @media (min-width: $desktop-xs) {
    margin: 0 0 40px 0;
    width: 300px;
  }

  @media (min-width: $desktop-m) {
    width: 350px;
  }

  .header {
    font-size: $font-m-3;

    @media (min-width: $desktop-m) {
      font-size: $font-m-5;
    }
  }

  &__link {
    &:not(:last-child) {
      border-bottom: solid 2px map-get($border, 'white-transparent-87');
    }

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 34px 0;
      line-height: 32px;
      color: map-get($background, 'white');
      transition: color 0.2s;

      &:hover {
        color: map-get($font, 'blue-light');
      }
    }

    &-text {
      margin-right: 25px;
      font-size: $font-s-4;
      line-height: $font-m-4;

      @media (min-width: $desktop-m) {
        font-size: $font-m-3;
        line-height: $font-m-7;
      }
    }

    &-arrow {
      display: block;
      height: 15px;
      width: 15px;
      padding: 6px;
      border: solid currentColor;
      border-width: 3px 3px 0px 0px;
      transform: rotate(45deg);
    }
  }

  @media (min-width: $desktop-xs) {
    min-height: 96px;
    padding: 40px 38px 30px 38px;
  }
}
