.page-hero-background {
  position: relative;
  margin: 0 $hero-gap 0 $hero-gap;
  padding: 20px 10px;
  background-image: map-get($background, 'navy-gradient-transparent-50');
  border-radius: 32px;

  @media (min-width: $desktop-xs) {
    padding: 20px 20px;
  }

  @media (min-width: $hugeScreens) {
    max-width: 2340px;
  }
}
