.card {
  &__description {
    font-size: $font-s-4;
  }

  &__icon {
    width: 70px;
  }

  &--big {
    .card {
      &__header {
        font-size: $font-m-6;
      }
    }
  }

  &--small {
    .card {
      &__header {
        font-size: $font-m-5;
      }
    }
  }

  &--highlight {
    border-radius: 6px;
    background: map-get($background, 'navy-gradient');
  }
}
