.power-cost {
  width: 100%;
  padding-top: 25px;

  @media (min-width: $tablet-xl) {
    width: fit-content;
    min-width: fit-content;
    padding-top: 0;
  }

  @media (min-width: $desktop-xs) {
    display: flex;
    align-items: center;
  }

  &__label-energy {
    font-size: $font-s-3;

    @media (min-width: $mobile-s) {
      font-size: $font-s-4;
    }

    @media (min-width: $desktop-xs) {
      font-size: $font-m-3;
    }
  }

  &__input-field {
    display: flex;
    align-items: center;
    margin-top: 15px;

    @media (min-width: $desktop-xs) {
      margin-top: 0;
      margin-left: 28px;
    }
  }

  &__input-energy {
    width: 90px;
    outline: none;
    padding: 0 15px;
    font-size: $font-m-4;
    color: map-get($font, 'white');
    text-align: center;
    background: transparent;

    @media (min-width: $mobile-s) {
      width: 110px;
      font-size: $font-m-6;
    }

    @media (min-width: $desktop-xs) {
      width: 120px;
      font-size: $font-m-8;
    }

    &:focus {
      color: map-get($font, 'white');
    }
  }

  &__input-energy-description {
    margin-left: 15px;
    color: map-get($font, 'white');
    font-size: $font-s-4;

    @media (min-width: $mobile-s) {
      font-size: $font-m-2;
    }
  }

  .pricing-estimator__counter-subtract-btn,
  .pricing-estimator__counter-add-btn {
    height: 41px;

    @media (min-width: $mobile-s) {
      height: 50px;
    }

    @media (min-width: $tablet-xl) {
      height: 50px;
    }
  }

  .pricing-estimator__counter-subtract-btn,
  .pricing-estimator__counter-add-btn {
    position: relative;
    width: 41px;
    border-radius: 4px;
    cursor: pointer;

    @media (min-width: $mobile-s) {
      width: 50px;
    }

    @media (min-width: $desktop-xs) {
      width: 66px;
    }

    &:disabled {
      pointer-events: none;
    }

    &:disabled::after,
    &:disabled::before {
      border-color: map-get($border, 'grey-light');
    }
  }

  .pricing-estimator__counter-subtract-btn {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 18px;
      transition: 0.25s;
    }

    &:hover::after {
      border-color: map-get($border, 'blue-light');
    }
  }

  .pricing-estimator__counter-add-btn {
    &::after,
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 18px;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &:hover::after,
    &:hover::before {
      border-color: map-get($border, 'blue-light');
    }
  }
}
