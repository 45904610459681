.hardware-devices {
  max-width: 720px;
  margin: auto;
  padding: 0 $spacing-s 30px $spacing-s;

  @media (min-width: $desktop-m) {
    max-width: 820px;
  }

  &__description {
    position: relative;
    z-index: 1;
    padding-bottom: 20px;
    text-align: center;
    font-size: $font-m-1;

    @media (min-width: $mobile-xl) {
      max-width: 460px;
      margin: auto;
    }

    @media (min-width: $desktop-xs) {
      max-width: 700px;
      font-size: $font-m-2;
    }

    @media (min-width: $desktop-s) {
      font-size: $font-m-3;
    }

    @media (min-width: $desktop-m) {
      font-size: $font-m-4;
    }
  }

  &__icons-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 600px;
    margin: auto;

    @media (min-width: $mobile-xl) {
      justify-content: center;
    }

    @media (min-width: $desktop-l) {
      max-width: 820px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(50% - 5px);
    padding: 20px 0;
    border-radius: 6px;

    @media (min-width: $mobile-s) {
      width: calc(33% - 5px);
    }

    @media (min-width: $mobile-xl) {
    }

    @media (min-width: $desktop-xs) {
    }

    @media (min-width: $desktop-s) {
    }

    @media (min-width: $desktop-m) {
    }

    svg {
      width: 80%;
      max-width: 90px;
      color: map-get($font, 'blue');

      @media (min-width: $desktop-xs) {
        max-width: 110px;
      }

      @media (min-width: $desktop-l) {
        max-width: 140px;
      }
    }

    span {
      width: 100%;
      margin-top: 10px;
      text-align: center;
    }

    &:nth-of-type(2) {
      // @media (min-width: $mobile-xl) {
      //   margin: 0 15px;
      // }

      // @media (min-width: $desktop-xs) {
      //   margin: 0 30px;
      // }
    }

    &:last-of-type {
      margin: 10px auto 0 auto;

      @media (min-width: $mobile-s) {
        margin: 0;
      }
    }
  }
}
