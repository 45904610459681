.main-section-navigation {
  display: flex;
  justify-content: center;
  gap: 20px;

  @media (min-width: $mobile-m) {
    justify-content: start;
    gap: 30px;
  }
}
