.page-hero-article {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 20px 0 60px 0;

  @media (min-width: $desktop-xs) {
    margin: 40px 0 100px 0;
  }

  @media (min-width: $desktop-m) {
    margin-bottom: 160px;
  }

  &__header {
    color: map-get($font, 'blue');
    font-size: $font-m-6;
    font-weight: 700;
    margin-bottom: 16px;

    @media (min-width: $mobile-xl) {
      max-width: 1158px;
      font-size: $font-l-3;
    }

    @media (min-width: $desktop-xs) {
      max-width: 1158px;
      font-size: $font-l-4;
    }

    @media (min-width: $desktop-s) {
      max-width: 1158px;
      font-size: $font-l-5;
    }
  }

  &__description {
    font-size: $font-m-1;
    margin: 0 3%;

    @media (min-width: $desktop-xs) {
      max-width: 620px;
    }
  }
}

.page-hero {
  padding: 0 $spacing-s;
}
