.support {
  position: relative;

  &__faq {
    max-width: 1340px;
    margin: 40px auto 0 auto;

    @media (min-width: $desktop-xs) {
      display: flex;
      padding: 0 $spacing-m;
    }
  }

  &__faq-communication {
    margin: 60px auto 0 auto;
    padding: 0 $spacing-s;

    @media (min-width: $desktop-xs) {
      min-width: 280px;
      margin-top: 30px;
      padding: 0;
    }

    @media (min-width: $desktop-s) {
      min-width: 340px;
    }

    @media (min-width: $desktop-m) {
      min-width: 400px;
    }
  }

  .section-title {
    margin: 160px 0 0 0;
  }
}

.support-discord,
.support-email {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 28px 30px 28px;
  border-radius: 32px;
  background: map-get($background, 'navy-gradient-transparent-50');
  border: 1px solid map-get($border, 'navy');
}

.support-discord__header,
.support-email__header {
  margin: 30px 0 15px 0;
  font-size: $font-m-3;

  @media (min-width: $desktop-m) {
    font-size: $font-m-5;
  }
}

.support-discord__footnotes,
.support-email__footnotes {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  padding-top: 30px;
  font-size: $font-s-3;

  .clock-icon {
    height: 20px;
    margin-right: 10px;
  }
}

.support-computing {
  margin: auto;
  max-width: 1920px;

  .section-title {
    margin: 50px 0 0 0;
  }

  &__faq {
    width: 100%;

    @media (min-width: $tablet-xl) {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 $spacing-m;
    }
  }

  &__faq-communication {
    height: 100%;
    margin: 50px auto 0 auto;
    padding: 0 $spacing-s;

    @media (min-width: $tablet-xl) {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      padding: 0;
    }

    @media (min-width: $desktop-xs) {
      gap: 75px;
    }
  }

  .support-discord,
  .support-email {
    justify-content: space-between;
    padding: 40px 28px 30px 28px;

    @media (min-width: $tablet-xl) {
      margin: 0;
      width: 400px;
      height: 400px;
    }

    @media (min-width: $desktop-m) {
      width: 500px;
      height: 500px;
    }
  }
}
