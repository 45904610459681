.mining {
  &__hero-description {
    margin-left: 3px;
    padding-bottom: 5px;
  }

  &__hero-footnotes {
    margin: 30px auto 20px auto;
    width: fit-content;
    text-align: center;
    font-size: $font-s-3;
    color: map-get($font, 'navy-light');

    @media (min-width: $desktop-xs) {
      display: flex;
      justify-content: center;
      font-size: $font-s-4;
    }

    &-item {
      display: flex;
      align-items: center;
      margin: 10px 20px;

      @media (min-width: $desktop-xs) {
        justify-content: center;
        margin-left: 30px;
      }

      svg {
        margin: 5px 10px 0 0;
      }
    }
  }

  &__description {
    position: relative;
    z-index: 1;
    max-width: 950px;
    margin: 0 auto 60px auto;
    padding: 0 $spacing-xs;
    line-height: 28px;
    text-align: center;
    font-size: $font-s-3;
    color: map-get($font, 'navy-light');

    @media (min-width: $mobile-xs) {
      padding: 0 $spacing-m;
    }

    @media (min-width: $tablet-xs) {
      padding: 0 $spacing-l;
    }

    @media (min-width: $desktop-xs) {
      margin: 0 auto 100px auto;
    }

    @media (min-width: $desktop-s) {
      padding: 0;
    }

    @media (min-width: $desktop-m) {
      margin: 0 auto 120px auto;
    }

    &-link {
      margin: 30px 0;
      color: map-get($font, 'grey');

      @media (min-width: $tablet-xs) {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        color: map-get($font, 'blue');
        font-weight: 600;
        transition: color 0.2s;
        margin-top: 10px;

        @media (min-width: $tablet-xs) {
          justify-content: flex-start;
          margin-top: 0px;
          margin-left: 10px;
        }

        span {
          margin-left: 5px;
        }

        &:hover {
          color: map-get($font, 'blue-light');
        }
      }
    }
  }
}

.pricing {
  .section-title {
    margin-bottom: 31px;
  }
}
