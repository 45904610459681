.collapse-card {
  min-height: 90px;
  background: map-get($background, 'navy');
  margin-bottom: 10px;
  border-radius: 10px;

  @media (min-width: $mobile-xl) {
    width: calc(100% - (2 * $spacing-m));
    margin: 0 auto 15px auto;
    border-radius: 16px;
  }

  @media (min-width: $desktop-xs) {
    width: 710px;
  }

  @media (min-width: $desktop-m) {
    width: 910px;
  }

  @media (min-width: $desktop-l) {
    width: 1000px;
  }

  &--open {
    padding-bottom: 35px;

    .collapse-card__btn-header {
      color: map-get($font, 'green-light');
    }

    .collapse-card__btn-toggle-arrow-icon {
      border-color: map-get($border, 'green-light');
    }
  }

  &__btn-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    height: 90px;
    width: 100%;
    padding: 0 24px 0 24px;
    color: map-get($font, 'white');
    background: map-get($background, 'transparent');
    border: none;
    font-size: $font-s-4;
    transition: 0.25s;
    cursor: pointer;

    &:hover {
      color: map-get($font, 'blue-light');

      .collapse-card__btn-toggle-arrow-icon {
        border-color: map-get($border, 'blue-light');
      }
    }
  }

  &__btn-header-title {
    padding-right: 15px;
  }

  &__btn-toggle-arrow {
    display: flex;
    height: 30px;
    width: 30px;
    justify-content: center;
    align-items: center;
  }

  &__btn-toggle-arrow-icon {
    border: solid map-get($border, 'white');
    border-width: 3px 0px 0px 3px;
    padding: 6px;
    transition: 0.2s linear;
    transform: rotate(135deg);

    &--open {
      transform: rotate(225deg);
    }
  }

  &__content {
    padding: 0 24px 0 24px;
    overflow: hidden;
    transition: 0.25s ease-in-out;

    &--open {
      overflow-x: auto;
    }
  }
}
