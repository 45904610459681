.download-check-tutorial {
  padding: 40px 28px 30px 28px;
  border-radius: 32px;
  background-image: map-get($background, 'blue-gradient');
  display: flex;
  flex-direction: column;
  align-items: center;

  .manual-icon {
    height: 74px;
  }

  &__header {
    margin: 20px 0 8px 0;
    font-size: $font-m-3;

    @media (min-width: $desktop-m) {
      font-size: $font-m-5;
    }
  }

  &__header-description {
    margin-bottom: 30px;
    font-size: $font-s-4;
    color: map-get($font, 'white');
  }

  &__btn {
    width: 210px;
    height: 48px;
    font-size: $font-s-4;
  }
}
