.header {
  font-weight: 500;
  color: map-get($font, 'white');

  &__description {
    color: map-get($font, 'grey-light');
  }
}

.hero-header {
  font-weight: 700;
  color: map-get($font, 'white');
  font-size: $font-l-4;

  @media (min-width: $mobile-m) {
    font-size: $font-l-5;
  }

  @media (min-width: $desktop-xs) {
    font-size: $font-l-6;
  }
}

.hero-description {
  font-size: $font-m-1;
}

.section-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 60px 0 60px 0;

  @media (min-width: $desktop-xs) {
    margin: 120px 0 100px 0;
  }

  @media (min-width: $desktop-m) {
    margin-top: 160px;
  }

  &__header,
  h1 {
    color: map-get($font, 'blue');
    font-size: $font-m-6;
    font-weight: 700;
    margin-bottom: 16px;

    @media (min-width: $mobile-xl) {
      max-width: 1158px;
      font-size: $font-l-3;
    }

    @media (min-width: $desktop-xs) {
      max-width: 1158px;
      font-size: $font-l-4;
    }

    @media (min-width: $desktop-s) {
      max-width: 1158px;
      font-size: $font-l-5;
    }
  }

  &__description,
  p {
    font-size: $font-m-1;
    margin: 0 3%;

    @media (min-width: $desktop-xs) {
      max-width: 620px;
    }
  }
}
