:root {
  // base shades group

  --white: #fff;
  --black: #000;
  --transparent: transparent;

  // blue shades group

  --blue: #00b3ff;
  --blue-light: #50cbff;

  // green shades group

  --green: #72af22;
  --green-light: #8edc29;

  // grey shades group

  --grey: #a7a7a7;
  --grey-dark: #393a47;
  --grey-darker: #333546;
  --grey-darkest: #303342;
  --grey-light: #cbcdd5;

  //navy shades group

  --navy: #222332;
  --navy-dark: #171b27;
  --navy-darker: #0c101c;
  --navy-light: #454e6a;
  --navy-lighter: #9ba1b2;

  // orange

  --orange: #e5a218;

  // red

  --red: #fe1041;

  // gradients group

  --navy-gradient: linear-gradient(#282b39, #212231);
  --background-gradient: #0c101c
    radial-gradient(rgba(44, 46, 64, 40%) 0%, #0c101c 50%) no-repeat 0
    calc(-0.2 * 100vw + 100px);
  --blue-to-green-gradient: linear-gradient(#009fe3, #afca0b);
  --navy-gradient-transparent-50: linear-gradient(
    rgba(34, 35, 50, 0.5),
    rgba(12, 16, 28, 0.5)
  );
  --navy-gradient-transparent-100: linear-gradient(
    rgba(34, 35, 50, 1),
    rgba(12, 16, 28, 0)
  );
  --blue-gradient: linear-gradient(
    to left,
    rgba(11, 152, 214, 1),
    rgba(19, 200, 221, 1)
  );
  --blue-to-black-gradient: linear-gradient(
    to left,
    rgba(4, 23, 35, 1),
    rgba(10, 49, 73, 1)
  );
  --white-to-transparent-gradient-left: linear-gradient(
    to left,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  --white-to-transparent-gradient-top: linear-gradient(
    to top,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  // transparent group

  --black-transparent-10: rgba(0, 0, 0, 0.9);
  --black-transparent-20: rgba(0, 0, 0, 0.8);
  --black-transparent-30: rgba(0, 0, 0, 0.7);
  --black-transparent-70: rgba(0, 0, 0, 0.3);
  --black-transparent-90: rgba(0, 0, 0, 0.1);
  --navy-transparent-50: rgba(34, 35, 50, 0.5);
  --navy-darker-transparent-90: rgba(12, 16, 28, 0.1);
  --navy-darker-transparent-50: rgba(12, 16, 28, 0.5);
  --white-transparent-87: rgba(255, 255, 255, 0.13);
}
