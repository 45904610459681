.start-computing {
  margin: 120px auto 30px auto;
  max-width: 2560px;
  width: 95%;

  @media (min-width: $desktop-xs) {
    width: 70%;
  }

  &__content {
    position: relative;
    padding: 60px $spacing-s 60px $spacing-s;
    background-image: map-get($background, 'blue-gradient');
    border-radius: 32px;
    overflow: hidden;
  }

  &__header,
  &__description {
    text-align: center;
  }

  &__header {
    font-size: $font-m-6;

    @media (min-width: $desktop-xs) {
      font-size: $font-l-2;
    }

    @media (min-width: $desktop-m) {
      font-size: $font-l-5;
    }
  }

  &__description {
    padding: 0 88px 0 88px;
    margin-top: 20px;
    font-size: $font-m-1;

    @media (min-width: $desktop-xs) {
      font-size: $font-m-2;
    }

    @media (min-width: $desktop-xs) {
      padding: 0 88px 0 88px;
    }

    @media (min-width: $desktop-m) {
      font-size: $font-m-4;
    }
  }

  &__links-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    @media (min-width: $desktop-xs) {
      flex-direction: row-reverse;
      justify-content: center;
      margin-top: 40px;
    }
  }

  &__sign-up {
    font-weight: 400;
    &:hover {
      color: map-get($font, 'white');
      background: map-get($background, 'black');
    }
  }

  &__background-left,
  &__background-right {
    position: absolute;
    width: 20%;
    height: auto;
    border-radius: 32px;
  }

  &__background-left {
    bottom: -15px;
    left: 0;

    svg {
      max-width: 100%;
    }
  }

  &__background-right {
    top: -15px;
    right: 0;

    svg {
      max-width: 100%;
    }
  }
}
