.pricing-list {
  margin: auto;

  @media (min-width: $desktop-xs) {
    margin: 0 30px 0 0;
  }

  @media (min-width: $desktop-m) {
    margin: 0 40px 0 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  &__header-title,
  &__header-description-title {
    font-size: $font-s-4;

    @media (min-width: $mobile-xs) {
      font-size: $font-m-3;
    }

    @media (min-width: $mobile-m) {
      font-size: $font-m-4;
    }
  }

  &__header-description {
    &-title,
    &-footnotes {
      display: block;
      text-align: right;
    }

    &-footnotes {
      font-size: $font-s-2;
      color: map-get($font, 'grey');
      margin-top: 10px;

      @media (min-width: $mobile-xs) {
        font-size: $font-s-3;
      }

      @media (min-width: $desktop-xs) {
        font-size: $font-s-2;
      }

      @media (min-width: $desktop-l) {
        font-size: $font-s-3;
      }
    }
  }

  &__table {
    border-radius: 32px;

    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      border-top: 1px solid map-get($border, 'navy');
      font-size: $font-s-3;

      &:first-child {
        border-top: none;
      }

      &-range-price {
        span {
          display: block;
          text-align: right;
          color: map-get($font, 'blue');

          .price-temporary-discount {
            display: inline-block;
            margin-right: 3px;
            text-decoration: line-through;
          }

          &:last-child {
            font-size: $font-s-2;

            @media (min-width: $mobile-xs) {
              font-size: $font-s-3;
            }

            @media (min-width: $desktop-xs) {
              font-size: $font-s-2;
            }

            @media (min-width: $desktop-l) {
              font-size: $font-s-3;
            }
          }
        }
      }

      @media (min-width: $mobile-xs) {
        font-size: $font-s-4;
      }

      @media (min-width: $mobile-m) {
        font-size: $font-m-1;
      }
    }
  }
}
