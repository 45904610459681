.footer-discord {
  padding: 32px 48px 32px 48px;
  background-color: map-get($background, 'navy');
  border-radius: 32px;
  width: 95%;
  max-width: 1704px;
  z-index: 1;

  margin-top: 80px;

  @media (min-width: $desktop-xs) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 30px;
    width: 70%;
    top: 120px;
    margin-top: 0;
  }

  .discord-logo {
    height: 54px;
  }

  .card {
    &__text-content {
      @media (min-width: $desktop-xs) {
        margin: 0 auto 0 60px;
      }
    }

    &__header {
      margin-top: 15px;

      @media (min-width: $desktop-xs) {
        margin-top: 0;
      }
    }

    &__description {
      margin-top: 9px;

      @media (min-width: $desktop-xs) {
        margin-top: 0;
        font-size: $font-s-2;
      }

      @media (min-width: $desktop-s) {
        font-size: $font-s-4;
      }

      @media (min-width: $desktop-m) {
        font-size: $font-m-1;
      }
    }
  }

  &__link {
    width: 210px;
    height: 48px;
    margin-top: 25px;

    @media (min-width: $desktop-xs) {
      width: 180px;
      margin-top: 0;
    }
  }
}
