.slider-image {
  overflow: hidden;
  padding: 12px;
  border: 1px solid map-get($border, 'navy');
  gap: 32px;
  align-items: center;
  background-image: map-get($background, 'navy-gradient-transparent-50');
  border-radius: 32px;

  @media (min-width: $tablet-xs) {
    padding: 16px;
  }

  @media (min-width: $desktop-m) {
    padding: 24px;
  }

  img {
    border-radius: 16px;
  }
}
