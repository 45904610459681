* {
  padding: 0;
  margin: 0;
  border: none;
  box-sizing: border-box;
}

:root {
  font-size: 16px;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Outfit', 'Arial', sans-serif;
  font-size: $font-s-4;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  background: map-get($background, 'navy-darker');
  background-size: 100% 100vw;
  font-size: $font-s-3;
  color: map-get($font, 'white');
  overflow: unset;
  max-width: 2560px;
  margin: auto;
  padding-right: 0;

  @media (min-width: $desktop-m) {
    font-size: $font-s-4;
  }
}

main {
  max-width: 100%;
  overflow: hidden;
}

a,
a:visited,
a:hover {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

.blue-text-marker {
  color: map-get($font, 'blue');
}
