.main-section-brands {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 400px;
  gap: 25px;

  @media (min-width: $tablet-s) {
    flex-wrap: nowrap;
    max-width: 100%;
    gap: 48px;
  }
}
