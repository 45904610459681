.features-samples {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 0 $spacing-s;

  @media (min-width: $mobile-m) {
    max-width: 360px;
    margin: auto;
  }

  @media (min-width: $tablet-m) {
    max-width: 735px;
  }

  @media (min-width: $desktop-xs) {
    display: flex;
    flex-wrap: wrap;
    max-width: 1280px;
  }

  @media (min-width: $desktop-l) {
    max-width: 1440px;
  }

  .card {
    min-width: 100%;
    min-height: 220px;
    margin-bottom: 80px;
    background: map-get($background, 'transparent');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media (min-width: $tablet-m) {
      display: block;
      text-align: left;
      min-width: 50%;
      max-width: 50%;
    }

    @media (min-width: $desktop-xs) {
      min-width: 33%;
      max-width: 33%;
    }

    svg {
      height: 80px;
      width: auto;
    }

    &__header {
      margin: 10px 0 25px 0;

      @media (min-width: $desktop-l) {
        font-size: $font-l-1;
      }
    }

    &__description {
      @media (min-width: $mobile-s) {
        max-width: 320px;
      }

      @media (min-width: $desktop-xs) {
        max-width: 240px;
      }

      @media (min-width: $desktop-l) {
        font-size: $font-m-3;
        max-width: 80%;
      }
    }
  }
}
