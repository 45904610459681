.feature-card {
  align-items: center;
  margin: 0 auto 90px auto;
  max-width: 1344px;

  @media (min-width: $desktop-xs) {
    display: flex;
  }

  &--invert {
    @media (min-width: $desktop-xs) {
      flex-direction: row-reverse;
    }
  }

  &__media-source,
  &__media-description {
    width: 100%;

    @media (min-width: $desktop-xs) {
      min-width: 50%;
      max-width: 50%;
    }
  }

  &__media-source {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    &-image {
      border-radius: 16px;
    }

    &-image-wrapper {
      user-select: none;
      flex: 1;
      overflow: hidden;
      padding: 12px;
      border: 1px solid map-get($border, 'navy');
      gap: 32px;
      align-items: center;
      background-image: map-get($background, 'navy-gradient-transparent-50');
      border-radius: 24px;

      @media (min-width: $tablet-xs) {
        padding: 16px;
        border-radius: 32px;
      }

      @media (min-width: $desktop-m) {
        padding: 24px;
        border-radius: 32px;
      }
    }
  }

  &__media-description {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    @media (min-width: $desktop-xs) {
      margin-top: 0;
    }
  }

  &__description-wrapper {
    width: 100%;

    @media (min-width: $desktop-xs) {
      width: 80%;
    }
  }

  &__link {
    margin: 28px auto 0 0;
  }
}
