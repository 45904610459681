.content-header {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 40px;
  text-align: center;

  @media (min-width: $mobile-m) {
    text-align: start;
  }
}
