.main-images {
  &__connection,
  &__system {
    width: 100%;
    max-width: 300px;
    height: 260px;

    @media (min-width: $desktop-xs) {
      width: 45%;
      max-width: 325px;
      height: 260px;
    }
  }

  &__connection {
    @media (min-width: $desktop-m) {
      position: relative;
      width: 335px;
      height: 261px;
    }
  }

  &__system {
    border-radius: 8px;

    @media (min-width: $desktop-xs) {
      padding: 0;
    }

    @media (min-width: $desktop-m) {
      position: absolute;
      margin-top: 50px;
      margin-left: 52%;
      width: 300px;
      height: 258px;
    }
  }
}

.content-images {
  display: none;

  @media (min-width: $desktop-xs) {
    flex: 1;
    display: flex;
    position: sticky;
    justify-content: center;
    justify-content: space-around;
  }

  @media (min-width: $desktop-m) {
    justify-content: normal;
    max-width: 600px;
  }
}

.content-images-slider {
  position: relative;
  width: 100%;
  // min-width: 325px;
  min-height: 100%;

  @media (min-width: $desktop-xs) {
    display: none;
  }

  .swiper-pagination {
    position: static;
    gap: 20px;
    margin-top: 16px;
    display: flex;
    justify-content: center;

    &-bullet {
      background: map-get($font, 'grey');
      opacity: 1;
      &-active {
        background: map-get($background, 'blue');
      }
    }
  }

  .swiper {
    min-height: 100%;
    max-width: 350px;
  }

  .swiper-wrapper {
    display: flex;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.image-loader {
  border-radius: 8px;
  background-color: map-get($background, 'navy');
  padding: 48px 10px 10px 10px;
  filter: blur(2px);

  &--content {
    border-radius: 8px;
    background-color: map-get($background, 'navy-dark');
    width: 100%;
    height: 100%;
  }
}

.slider-loader {
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: $desktop-xs) {
    display: none;
  }
}
