.navigation-bar {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 78px;
  margin: 0 auto;
  padding: 20px $spacing-s 10px $spacing-s;
  background: map-get($background, 'navy-darker');
  transition: transform 0.2s ease-in-out;

  @media (min-width: $tablet-xl) {
    height: 90px;
    padding: 0 $spacing-xs 0 $spacing-xs;
  }

  @media (min-width: $desktop-s) {
    height: 95px;
    padding: 0 $spacing-m 0 $spacing-m;
  }

  @media (min-width: $desktop-m) {
    height: 112px;
    padding: 0 $spacing-l 0 $spacing-l;
  }

  @media (min-width: $hugeScreens) {
    max-width: 2340px;
  }

  &--hidden {
    transform: translateY(-100%);
  }

  &__logo {
    display: flex;
    align-items: center;
    width: fit-content;
    height: 100%;
    margin: auto;

    @media (min-width: $tablet-xl) {
      height: 44px;
      margin: 0 25px 0 0;
    }
  }

  &__logo-img {
    height: 100%;
    margin-right: 14px;
  }

  &__logo-brand-name {
    display: block;
    height: 40%;
    width: 151px;

    path {
      transition: 0.2s;
    }

    @media (min-width: $tablet-xl) {
      display: none;
    }

    @media (min-width: $desktop-xs) {
      display: block;
      margin-top: 2px;
    }
  }

  &__logo-simplepod {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: auto;

    @media (min-width: $tablet-xl) {
      margin: 0 25px 0 0;
    }
  }

  &__mobile-curtain {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: map-get($background, 'black-transparent-20');

    @media (min-width: $tablet-xl) {
      display: none;
    }
  }
}
