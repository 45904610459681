.flash-drive-wrapper--mobile {
  margin: 60px $multi-section-gap 30px $multi-section-gap;

  @media (min-width: $desktop-s) {
    display: none;
  }
}

.title {
  position: relative;
}

.flash-drive__content-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 250px;
}

.flash-drive__content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 400px;
}

.flash-drive__background {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 1050px;
  max-height: 574px;
  box-shadow: 0 -20px 100px 100px map-get($background, 'navy-darker') inset;
  margin-inline: auto;

  img {
    z-index: -1;
  }

  @media (min-width: $desktop-s) {
    max-width: none;
    max-height: none;
    top: 0;
    left: 0;
  }
}

.flash-drive-wrapper--desktop {
  display: none;

  @media (min-width: $desktop-s) {
    display: initial;

    position: relative;
    margin-bottom: 100px;

    .section-title {
      margin-bottom: -100px;
    }

    .section-title__header {
      z-index: 1;
      margin-bottom: 0;
    }
  }
}

.flash-drive {
  @media (min-width: $desktop-s) {
    height: 800px;
  }

  &__content {
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;

    &-text-wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;

      @media (min-width: $desktop-s) {
        display: flex;
        height: 100%;
        justify-content: space-between;
      }
    }

    &-link {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 25px auto 0 auto;
      padding: 25px 35px;
      font-size: $font-m-2;
      text-align: center;
      gap: 22px;
      width: 90%;
      border-radius: 32px 32px 0 0;
      background: map-get($background, 'navy-gradient-transparent-100');

      @media (min-width: $desktop-s) {
        padding: 35px 45px;
        font-size: $font-m-3;
        text-align: center;
        margin: 0 auto;
        width: 40%;
      }
    }
  }

  // &__link {
  //   z-index: 2;
  //   margin-top: 60px;

  //   @media (min-width: $desktop-s) {
  //     margin-top: 80px;
  //   }
  // }

  // &__content-top-background,
  // &__content-bottom-background {
  //   // position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 50%;
  //   background: transparent linear-gradient(180deg, #0c101c00 0%, #0c101c 100%)
  //     0% 0% no-repeat padding-box;
  //   opacity: 0.8;
  // }

  // &__content-top-background {
  //   top: 0;
  //   transform: matrix(-1, 0, 0, -1, 0, 0);
  // }

  // &__content-bottom-background {
  //   top: 50%;
  // }

  // &__content-arrow {
  //   display: flex;
  //   justify-content: center;

  //   svg {
  //     height: 28px;
  //     transform: rotate(90deg);

  //     @media (min-width: $desktop-xs) {
  //       height: 38px;
  //     }

  //     @media (min-width: $desktop-s) {
  //       height: 55px;
  //       transform: none;
  //     }
  //   }
  // }

  // &__device-wrapper {
  //   position: relative;
  //   max-height: 130px;
  //   overflow: hidden;

  //   @media (min-width: $tablet-m) {
  //     max-height: 180px;
  //   }

  //   @media (min-width: $desktop-s) {
  //     overflow: visible;
  //   }
  // }

  // &__device-img {
  //   position: relative;
  //   z-index: 3;
  //   top: 0;
  //   width: 130px;
  //   height: 300px;
  //   margin: auto;
  //   z-index: 3;
  //   transform: rotate(90deg) translate(-70%, 0);

  //   @media (min-width: $mobile-xl) {
  //     height: 320px;
  //     transform: rotate(90deg) translate(-75%, 0);
  //   }

  //   @media (min-width: $tablet-m) {
  //     width: 150px;
  //     height: 400px;
  //   }

  //   @media (min-width: $desktop-s) {
  //     transform: translate(-15%, -23%);
  //   }

  //   @media (min-width: $desktop-m) {
  //     width: 180px;
  //     transform: translate(-10%, -23%);
  //   }
  // }
}
