.sign-menu {
  display: flex;
  flex-direction: column;
  padding: 0 24px 50px 24px;

  @media (min-width: $tablet-xl) {
    flex-direction: row-reverse;
    align-items: center;
    margin-right: 20px;
    padding: 0;
  }

  &__sign-up,
  &__sign-in,
  &__to-dashboard {
    width: 100%;
    max-width: 320px;
    height: 48px;
    margin: auto;
    border-radius: 4px;

    @media (min-width: $tablet-xl) {
      height: 34px;
      font-size: $font-s-3;
    }

    @media (min-width: $desktop-m) {
      height: 48px;
      font-size: $font-s-4;
    }
  }

  &__sign-up {
    margin-top: 50px;

    @media (min-width: $mobile-xs) {
      margin-top: 60px;
    }

    @media (min-width: $tablet-xl) {
      width: 80px;
      margin-top: 0px;
    }

    @media (min-width: $desktop-xs) {
      width: 100px;
    }

    @media (min-width: $desktop-m) {
      width: 140px;
    }
  }

  &__sign-in {
    margin-top: 15px;

    @media (min-width: $tablet-xl) {
      width: 80px;
      margin-top: 0px;
      margin-right: 15px;
    }

    @media (min-width: $desktop-m) {
      width: 103px;
    }
  }

  &__to-dashboard {
    margin-top: 50px;

    @media (min-width: $mobile-xs) {
      margin-top: 60px;
      width: 150px;
    }

    @media (min-width: $tablet-xl) {
      margin-top: 0px;
    }

    @media (min-width: $desktop-m) {
      width: 140px;
    }
  }
}
