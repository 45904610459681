.total-cost {
  position: sticky;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;

  &__header {
    width: 100%;
    margin-right: 10px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: $font-s-4;

    @media (min-width: $mobile-s) {
      font-size: $font-m-1;
    }

    @media (min-width: $mobile-xl) {
      width: fit-content;
      margin-bottom: 0;
    }

    @media (min-width: $desktop-xs) {
      font-size: $font-m-2;
    }
  }

  &__profit {
    padding: 2px 0;
    color: map-get($font, 'green-light');
    font-size: $font-m-1;

    @media (min-width: $desktop-xs) {
      font-size: $font-m-2;
    }

    &-unit {
      color: map-get($font, 'navy-lighter');
      font-size: $font-s-3;

      @media (min-width: $desktop-xs) {
        font-size: $font-s-4;
      }
    }

    &--muted {
      color: map-get($font, 'navy-lighter');
    }

    &--danger {
      color: map-get($font, 'red');
    }

    &:first-of-type {
      margin-right: 9px;
    }
  }
}
