input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input {
  border-radius: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='search'] {
  -webkit-appearance: none;
}
