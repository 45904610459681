.custom-inner-wrapper {
  p {
    max-width: 720px;
    margin: 15px auto;
    padding: 0 $spacing-s;
    font-size: $font-s-4;
    line-height: 1.5;
  }

  a:not(.btn) {
    color: map-get($font, 'blue');
    transition: 0.25s;

    &:hover {
      color: map-get($font, 'blue-light');
    }
  }
}

.collapse-card__content {
  line-height: 1.5;

  strong {
    font-weight: 600;
  }

  ul {
    padding: 0 0 20px 40px;
    list-style-type: disc;

    li {
      ul {
        list-style-type: circle;
      }
    }
  }

  td {
    padding-right: 15px;
  }

  p {
    margin-bottom: 15px;
  }
}
