.navigation-bar-toggle {
  z-index: 12;
  width: 30px;
  height: 20px;
  padding: 5px;
  border: none;
  background: map-get($background, 'transparent');
  box-sizing: content-box;
  cursor: pointer;

  @media (min-width: $tablet-xl) {
    display: none;
  }

  span {
    display: block;
    width: 100%;
    border-bottom: solid 2px map-get($border, 'white');
    transition: 0.2s ease-in-out;
  }

  span:not(:nth-child(1)) {
    margin-top: 7px;
  }

  &:focus {
    outline: none;
  }

  &--open {
    span:nth-child(1) {
      transform: rotate(45deg) translate(5px, 10px);
    }

    span:nth-child(2) {
      width: 0;
    }

    span:nth-child(3) {
      transform: rotate(-45deg) translate(3px, -8px);
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      span:nth-child(1) {
        border-color: map-get($border, 'blue');
      }

      span:nth-child(2) {
        width: 70%;
        border-color: map-get($border, 'white');
      }

      span:nth-child(3) {
        border-color: map-get($border, 'blue');
      }
    }

    &--open:hover {
      span:nth-child(1),
      span:nth-child(3) {
        border-color: map-get($border, 'blue');
      }

      span:nth-child(2) {
        width: 0;
      }
    }
  }
}
