.home-video {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1920px;
  margin: 100px 3% 0 3%;

  @media (min-width: $desktop-m) {
    padding: 0;
    margin: 100px $section-gap 0 $section-gap;
  }
}
