.learn-more-link {
  display: flex;
  align-items: center;
  color: map-get($font, 'blue');
  font-size: $font-s-4;
  margin: 38px 0;
  transition: color 0.2s;

  .arrow-circle-icon {
    path,
    g {
      transition: stroke 0.2s;
    }
  }

  &:hover {
    color: map-get($font, 'blue-light');

    .arrow-circle-icon {
      path,
      g {
        stroke: map-get($font, 'blue-light');
      }
    }
  }

  .arrow-circle-icon {
    margin-left: 8px;
  }

  @media (min-width: $desktop-xs) {
    font-size: $font-m-1;
  }

  @media (min-width: $desktop-m) {
    font-size: $font-m-3;
  }
}
