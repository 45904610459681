.page-error {
  max-width: 400px;
  margin: 40px auto 80px auto;
  padding: 0 $spacing-s;

  &__header {
    font-size: $font-l-4;
    font-weight: 400;
    color: map-get($font, 'blue-light');
  }

  &__subheader,
  &__header-description {
    color: map-get($font, 'grey-light');
  }

  &__subheader {
    font-size: $font-m-4;
  }

  &__header-description {
    margin-top: 15px;
    font-size: $font-s-4;
  }
}
