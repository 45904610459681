.support-email {
  margin: 40px auto 0 auto;

  .envelope-icon {
    width: 92px;
  }

  &__link {
    display: block;
    width: 210px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    font-size: $font-s-4;
    color: map-get($background, 'white');
    background: map-get($background, 'navy');
    border-radius: 6px;

    &:hover {
      color: map-get($font, 'blue-light');
    }
  }
}
