.changelog-image {
  width: 300px;
  margin-top: 10px;
  border-radius: 4px;
  border: solid 2px map-get($border, 'white-transparent-87');
  overflow: hidden;
  transition: 0.2s;
  cursor: pointer;

  & > span {
    position: unset !important;
  }

  .custom-img {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }

  &:hover {
    opacity: 0.6;
  }

  &__view-box {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: map-get($background, 'black-transparent-30');
    cursor: pointer;
  }

  &__view-box-content {
    position: relative;
    width: 90%;
    height: 80vh;
  }
}
