.page-hero {
  width: 100%;
  margin: auto;
  background: transparent;

  @media (min-width: $desktop-m) {
    padding: 0 $spacing-l;
  }

  &__small-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 20px;
    gap: 10px;

    @media (min-width: $mobile-m) {
      margin: 30px;
    }

    @media (min-width: $tablet-xs) {
      margin: 40px 0;
    }

    @media (min-width: $desktop-xs) {
      margin: 50px 0;
    }

    &-item {
      &:not(:last-child) {
        @media (min-width: $tablet-xs) {
          margin-right: 30px;
        }
      }

      a {
        color: map-get($font, 'white');
        transition: color 0.2s;

        &:hover {
          color: map-get($font, 'blue-light');
        }
      }
    }
  }

  &__header {
    margin: auto;
    font-size: $font-m-5;
    text-align: center;

    @media (min-width: $tablet-m) {
      font-size: $font-l-1;
    }

    @media (min-width: $tablet-xl) {
      font-size: $font-l-3;
    }

    @media (min-width: $desktop-s) {
      font-size: $font-l-5;
    }

    @media (min-width: $desktop-m) {
      max-width: 1158px;
      font-size: $font-l-6;
    }
  }

  &__description {
    margin: 25px auto 45px auto;
    font-size: $font-m-1;
    text-align: center;
    line-height: 1.8;

    @media (min-width: $desktop-xs) {
      font-size: $font-m-2;
    }

    @media (min-width: $desktop-m) {
      font-size: 1.375rem;
    }
  }
}
