.main-section {
  gap: 30px;
  display: flex;
  flex-direction: column;
  padding-top: 10px;

  @media (min-width: $mobile-m) {
    gap: 40px;
    padding: 15px $hero-gap 15px $hero-gap;
  }

  @media (min-width: $desktop-m) {
    gap: 80px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $desktop-xs) {
      align-items: start;
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 25px;
      margin-bottom: 25px;
      max-width: 100%;

      @media (min-width: $desktop-xs) {
        display: flex;
        flex-direction: column;
        gap: 50px;
        margin-bottom: 32px;
      }

      @media (min-width: $desktop-m) {
        display: flex;
        flex-direction: row;
        gap: 112px;
        margin-bottom: 152px;
        flex: 2;
      }
    }
  }

  &__brands {
    position: relative;
    display: flex;
    gap: 48px;
  }
}
.main-section-wrapper {
  position: relative;

  .page-hero-background {
    @media (min-width: $desktop-xs) {
      padding: 50px 50px;
    }

    @media (min-width: $desktop-m) {
      padding-top: 48px;
      padding-bottom: 80px;
    }

    @media (min-width: $desktop-xl) {
      padding-top: 48px;
      padding-bottom: 158px;
    }

    @media (min-width: $hugeScreens) {
      max-width: 2340px;
    }
  }
}
