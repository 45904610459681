.support-faq-questions {
  @media (min-width: $desktop-xs) {
    width: 100%;
    margin-right: 40px;
  }

  @media (min-width: $desktop-s) {
    margin-right: 60px;
  }

  @media (min-width: $desktop-l) {
    margin-right: 80px;
  }

  &__input-field {
    display: flex;
    align-items: center;
    height: 55px;
    width: calc(100% - (2 * $spacing-s));
    margin: 30px auto 40px auto;
    border: 2px solid map-get($border, 'navy');
    border-radius: 12px;

    @media (min-width: $desktop-xs) {
      width: 100%;
    }

    &:focus-within {
      border-color: map-get($border, 'blue');
    }

    .search-icon {
      height: 25px;
      margin: 0 20px;
      padding: 0 2px;
    }
  }

  &__input {
    display: block;
    width: 100%;
    height: 100%;
    padding-right: 15px;
    background: map-get($background, 'transparent');
    color: map-get($background, 'white');
    outline: none;
    font-size: inherit;

    &::placeholder {
      font-size: $font-s-4;
    }
  }

  &__groups-header {
    margin: 50px 0 25px 0;
    padding: 0 $spacing-s;
    font-weight: 400;
  }

  .collapse-card {
    @media (min-width: $desktop-xs) {
      width: 100%;
    }
  }

  &__groups-no-data {
    text-align: center;
    font-size: $font-m-1;
  }
}
