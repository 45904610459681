.pricing-estimator {
  margin: 30px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;

  @media (min-width: $desktop-xs) {
    margin: 0;
  }

  &__header {
    margin-bottom: 30px;
    font-size: $font-s-4;

    @media (min-width: $mobile-xs) {
      font-size: $font-m-2;
    }

    @media (min-width: $mobile-s) {
      font-size: $font-m-2;
    }

    @media (min-width: $mobile-m) {
      font-size: $font-m-3;
    }

    @media (min-width: $desktop-m) {
      margin-bottom: 22px;
      font-size: $font-m-4;
    }

    @media (min-width: $desktop-l) {
      margin-bottom: 28px;
    }
  }

  &__counter-wrapper {
    width: 100%;
    margin: 0 42px 0 42px;
  }

  &__counter-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    // &:first-of-type {
    //   margin-bottom: 25px;
    // }
  }

  &__counter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
  }

  &__counter-label {
    display: block;
    margin-bottom: 12px;
    width: fit-content;
    font-size: $font-s-4;
  }

  &__counter-subtract-btn,
  &__counter-add-btn,
  &__counter-input {
    height: 63px;
    border-radius: 16px;
  }

  &__counter-subtract-btn,
  &__counter-add-btn {
    position: relative;
    width: 63px;
    min-width: 50px;
    max-width: 63px;
    cursor: pointer;
    border: solid 2px map-get($border, 'blue');
    background-color: transparent;

    &:disabled {
      pointer-events: none;
    }

    &:disabled::after,
    &:disabled::before {
      border-color: map-get($border, 'grey-light');
    }
  }

  &__counter-subtract-btn {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 23px;
      border-bottom: 3px solid map-get($border, 'blue');
      border-radius: 1px;
      transition: 0.25s;
    }

    &:hover::after {
      border-color: map-get($border, 'blue-light');
    }
  }

  &__counter-add-btn {
    &::after,
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 23px;
      border-bottom: 3px solid map-get($border, 'blue');
      border-radius: 1px;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &:hover::after,
    &:hover::before {
      border-color: map-get($border, 'blue-light');
    }
  }

  &__counter-input {
    width: min-content;
    font-size: $font-m-1;
    text-align: center;
    border: 1px solid map-get($border, 'navy');
    background: rgba(12, 16, 28, 1);
    color: map-get($font, 'white');
    align-items: center;
    width: 88%;

    &:focus {
      outline: none;
      border-color: map-get($border, 'blue-light');
    }
  }

  &__counter-total {
    display: block;
    font-size: $font-l-3;
    color: map-get($font, 'blue');
    word-break: break-all;
  }

  &__counter-total-description {
    font-size: $font-s-4;
    padding-top: 70px;

    span {
      display: block;

      &:first-child {
        font-size: $font-m-2;
        margin-top: 5px;
      }

      &:last-child {
        margin-top: 8px;
        font-size: $font-s-3;
        color: map-get($font, 'grey');
      }
    }
  }
}
