.pricing-table {
  position: relative;

  &__wrapper {
    @media (min-width: $desktop-xs) {
      width: 100%;
      padding: 0 $spacing-s;
      display: flex;
      justify-content: center;
    }
  }

  &__footnotes {
    font-size: $font-m-3;
    margin-top: 20px;

    &-text {
      margin-bottom: 20px;
    }

    @media (min-width: $mobile-l) {
      margin-top: 0;
      padding: 75px 75px 0 75px;
    }
  }
}

.pricing-list,
.pricing-estimator {
  width: 100%;

  padding: 23px;
  background-image: map-get($background, 'navy-gradient-transparent-50');
  border-radius: 32px;
  border: 1px solid map-get($border, 'navy');

  @media (min-width: $desktop-xs) {
    max-width: 546px;
  }

  @media (min-width: $tablet-s) {
    padding: 33px;
  }
}
