.supported-programs {
  max-width: 920px;
  margin: auto;
  padding: 0 $spacing-s;

  @media (min-width: $desktop-m) {
    max-width: 1160px;
  }

  &__list-wrapper {
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: solid 1px map-get($border, 'navy');
  }

  &__list-header,
  &__custom-header {
    display: flex;
    align-items: center;

    svg {
      min-width: 50px;
      max-width: 50px;

      @media (min-width: $desktop-m) {
        min-width: 60px;
        max-width: 60px;
      }
    }

    h3 {
      margin-left: 15px;
      font-size: $font-m-4;
      font-weight: 500;

      @media (min-width: $desktop-m) {
        margin-left: 30px;
      }
    }
  }

  &__list-header {
    margin-bottom: 25px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
  }

  &__list-item {
    margin: 5px;
    padding: 10px 20px;
    color: map-get($font, 'blue');
    text-align: center;
    font-size: $font-s-4;
    border-radius: 6px;
    background: map-get($background, 'blue-to-black-gradient');
    text-transform: uppercase;

    @media (min-width: $desktop-m) {
      padding: 10px 25px;
      font-size: $font-m-3;
    }
  }

  &__custom {
    @media (min-width: $desktop-xs) {
      display: flex;
    }
  }

  &__custom-header {
    @media (min-width: $mobile-xl) {
      align-items: flex-start;

      h3 {
        margin-top: 5px;
      }
    }

    @media (min-width: $desktop-m) {
      h3 {
        margin-top: 0;
      }
    }
  }

  &__custom-description {
    font-size: $font-s-4;
    text-align: left;
    color: map-get($font, 'grey');
    margin-top: 2px;

    @media (min-width: $mobile-xl) {
      padding-left: 65px;
    }

    @media (min-width: $desktop-m) {
      padding-left: 90px;
    }
  }

  &__custom-link {
    margin-top: 35px;

    @media (min-width: $desktop-xs) {
      margin-top: 10px;
    }
  }
}
