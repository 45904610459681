.features-examples {
  position: relative;

  &__wrapper {
    max-width: 520px;
    margin: auto;
    padding: 0 $spacing-s;

    @media (min-width: $desktop-xs) {
      max-width: 1080px;
    }
  }

  &__card {
    .feature-card__media-source {
      position: relative;
      height: 100%;
      width: 100%;
      max-width: 520px;
      border-radius: 6px;
    }

    .feature-card__media-source > div {
      top: 0;
      left: 0;
      overflow: hidden;
    }

    .example__image-btn {
      position: absolute;
      left: 50%;
      bottom: -24px;
      transform: translateX(-50%);
    }

    .feature-card__media-description-image-btn--visible {
      .feature-description__header {
        margin-top: 30px;

        @media (min-width: $desktop-xs) {
          margin-top: 0;
        }
      }
    }

    .feature-description__header {
      @media (min-width: $desktop-s) {
        font-size: $font-m-6;
      }

      @media (min-width: $desktop-m) {
        font-size: $font-l-1;
      }
    }
  }

  .circuit-green-rotate-second {
    display: none;

    @media (min-width: $desktop-xs) {
      display: block;
      position: absolute;
      z-index: -1;
      top: 15%;
      left: 0;
      width: 15%;
    }
  }
}
