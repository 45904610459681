.mining-calculator {
  &__summary {
    margin: 35px $spacing-m 30px $spacing-m;
    padding: 20px $spacing-xs;
    background: map-get($background, 'navy');
    border-radius: 8px;

    @media (min-width: $mobile-xl) {
      max-width: 100%;
      margin: 65px $spacing-m 40px $spacing-m;
      padding: 20px $spacing-s;
      border-radius: 16px;
    }
  }

  &__summary-power-cost {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @media (min-width: $tablet-xl) {
      flex-wrap: nowrap;
    }
  }

  &__summary-power-cost-header {
    width: 100%;
    min-width: 100%;
    font-weight: 400;
    font-size: $font-s-4;
    padding-bottom: 25px;
    border-bottom: 2px solid map-get($border, 'grey-dark');
    color: white;

    @media (min-width: $mobile-s) {
      font-size: $font-m-3;
    }

    @media (min-width: $tablet-xl) {
      width: fit-content;
      min-width: fit-content;
      margin-right: 45px;
      padding-bottom: 0;
      padding-right: 45px;
      font-size: $font-m-4;
      border-bottom: none;
      border-right: 2px solid map-get($border, 'grey-dark');
    }

    @media (min-width: $desktop-xs) {
      font-size: $font-m-5;
    }
  }
}
