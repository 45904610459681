.revenue-cell {
  min-width: 180px;
  color: map-get($font, 'navy-lighter');
  font-size: $font-s-2;

  @media (min-width: $mobile-m) {
    font-size: $font-s-3;
  }

  &__profit {
    margin-top: 5px;
    color: map-get($font, 'green-light');
    font-size: $font-s-3;

    @media (min-width: $desktop-xs) {
      font-size: $font-m-1;
    }

    &--inactive {
      color: map-get($font, 'navy-lighter');
    }

    &--negative {
      color: map-get($font, 'red');
    }
  }

  &--monthly {
    .revenue-cell__profit {
      color: map-get($font, 'blue-light');

      &--inactive {
        color: map-get($font, 'navy-lighter');
      }

      &--negative {
        color: map-get($font, 'red');
      }
    }
  }
}
