.deployment {
  margin-top: 60px;

  @media (min-width: $desktop-xs) {
    margin-top: 120px;
  }

  @media (min-width: $desktop-m) {
    margin-top: 160px;
  }

  &__description {
    position: relative;
    z-index: 3;
    margin-bottom: 80px;

    @media (min-width: $desktop-xs) {
      margin-bottom: 120px;
      max-width: 620px;
    }
  }
}
