// page gap margin
$hero-gap: 6%; // 6% * 1920px = 115.2px
$section-gap: 12%; // 12% * 1920px = 230.4px
$multi-section-gap: 7%; // 7% * 1920px = 134.4px

// main app container margins

$spacing-xs: 15px;
$spacing-s: 20px;
$spacing-m: 25px;
$spacing-l: 50px;

// fonts size

$font-s-1: 0.6875rem; // 11px
$font-s-2: 0.75rem; // 12px
$font-s-3: 0.875rem; // 14px
$font-s-4: 1rem; // 16px

$font-m-1: 1.125rem; // 18px
$font-m-2: 1.25rem; // 20px
$font-m-3: 1.3125rem; // 21px
$font-m-4: 1.5rem; // 24px
$font-m-5: 1.75rem; // 28px
$font-m-6: 1.875rem; // 30px
$font-m-7: 2rem; // 32px
$font-m-8: 2.25rem; // 36px

$font-l-1: 2.375rem; // 38px
$font-l-2: 2.5rem; // 40px
$font-l-3: 2.625rem; // 42px
$font-l-4: 2.75rem; // 44px
$font-l-5: 3.5rem; // 56px
$font-l-6: 4.375rem; // 70px
$font-l-7: 4.5rem; // 72px

// media break points

$mobile-xs: 375px;
$mobile-s: 390px;
$mobile-m: 450px;
$mobile-l: 500px;
$mobile-xl: 550px;

$tablet-xs: 700px;
$tablet-s: 720px;
$tablet-m: 750px;
$tablet-l: 768px;
$tablet-xl: 860px;

$desktop-xs: 1024px;
$desktop-s: 1280px;
$desktop-m: 1440px;
$desktop-l: 1600px;
$desktop-xl: 1640px;
$hugeScreens: 2560px;

// media break points exceptions

$mobile-download-tabs-s: 400px;
