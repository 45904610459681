.supported-miner-programs {
  margin: 0 auto 60px auto;

  &__description {
    color: map-get($font, 'grey-light');

    @media (min-width: $mobile-xl) {
      max-width: 80%;
    }

    @media (min-width: $desktop-xs) {
      max-width: 960px;
    }
  }

  .section-title {
    margin: 150px 0 40px 0;
  }
}
