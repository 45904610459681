.app {
  padding-top: 100px;

  @media (min-width: $tablet-xs) {
    padding-top: 90px;
  }

  @media (min-width: $desktop-m) {
    padding-top: 112px;
  }
}
