.feature-description {
  &__header {
    font-size: $font-m-3;
    font-weight: 700;
    color: map-get($font, 'blue');

    @media (min-width: $mobile-xl) {
      font-size: $font-m-5;
    }
  }

  &__description {
    margin-top: 25px;
    font-size: $font-s-4;

    @media (min-width: $mobile-xl) {
      font-size: $font-m-1;
    }

    &-list {
      list-style: disc;
      padding: 10px 0 10px 0;
      font-size: $font-s-4;

      @media (min-width: $mobile-xl) {
        font-size: $font-m-1;
      }

      &-item {
        display: flex;
        align-items: center;
        gap: 10px;
        padding-left: 10px;

        &::before {
          content: '•';
          font-size: 15px;
        }
      }

      li:not(:last-child) {
        margin-bottom: 7px;
      }
    }
  }

  &__footer {
    font-size: $font-s-4;

    @media (min-width: $mobile-xl) {
      font-size: $font-m-1;
    }
  }
}
