.changelog-label {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
  padding: 0 $spacing-xs;

  @media (min-width: $mobile-xl) {
    padding: 0;
  }

  &__timestamp {
    margin-right: 5px;
    font-size: 0.875rem;
    color: map-get($font, 'navy-lighter');
  }

  &__version {
    font-size: 1.375rem;
    color: map-get($font, 'white');
  }
}
