.support-discord {
  margin: auto;

  .discord-logo {
    height: 54px;
  }

  &__header {
    .support-discord--simplified & {
      margin: 10px 0 20px 0;
    }
  }

  &__header-description {
    margin-bottom: 30px;
    font-size: $font-s-4;
    color: map-get($font, 'white');
  }

  &__btn {
    width: 210px;
    height: 48px;
    font-size: $font-s-4;
  }
}
